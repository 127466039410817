import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 8 17" {...props} >
      <path
        d="M1.70477 16.499H4.97225V8.42946H7.24528L7.45838 5.71641H4.97225C4.97225 5.71641 4.97225 4.67294 4.97225 4.18598C4.97225 3.55989 5.11432 3.28163 5.75361 3.28163C6.25083 3.28163 7.52941 3.28163 7.52941 3.28163V0.499023C7.52941 0.499023 5.68257 0.499023 5.25638 0.499023C2.84129 0.499023 1.70477 1.5425 1.70477 3.55989C1.70477 5.29902 1.70477 5.71641 1.70477 5.71641H0V8.42946H1.70477V16.499Z"
      />
    </Svg>
  );
};

export default Icon;
