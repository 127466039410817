export const farmAuctionABI = [
  {
    inputs: [
      { internalType: 'address', name: '_cakeToken', type: 'address' },
      { internalType: 'address', name: '_operatorAddress', type: 'address' },
      { internalType: 'uint256', name: '_maxAuctionLength', type: 'uint256' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'AuctionBid',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      { indexed: false, internalType: 'bool', name: 'isAdmin', type: 'bool' },
    ],
    name: 'AuctionClaim',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'participationLimit', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'numberParticipants', type: 'uint256' },
    ],
    name: 'AuctionClose',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'startBlock', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'endBlock', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'initialBidAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'leaderboard', type: 'uint256' },
    ],
    name: 'AuctionStart',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint256', name: 'maxAuctionLength', type: 'uint256' }],
    name: 'NewMaxAuctionLength',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'account', type: 'address' }],
    name: 'NewOperatorAddress',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'token', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'TokenRecovery',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'account', type: 'address' }],
    name: 'WhitelistAdd',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'account', type: 'address' }],
    name: 'WhitelistRemove',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'address[]', name: '_bidders', type: 'address[]' }],
    name: 'addWhitelist',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'auctionBids',
    outputs: [
      { internalType: 'uint256', name: 'totalAmount', type: 'uint256' },
      { internalType: 'bool', name: 'hasClaimed', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'auctions',
    outputs: [
      { internalType: 'enum FarmAuction.Status', name: 'status', type: 'uint8' },
      { internalType: 'uint256', name: 'startBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'endBlock', type: 'uint256' },
      { internalType: 'uint256', name: 'initialBidAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'leaderboard', type: 'uint256' },
      { internalType: 'uint256', name: 'leaderboardThreshold', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
    name: 'bid',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'cakeToken',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_auctionId', type: 'uint256' }],
    name: 'claimAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_auctionId', type: 'uint256' },
      { internalType: 'address[]', name: '_bidders', type: 'address[]' },
    ],
    name: 'claimAuctionLeaderboard',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { internalType: 'address', name: 'bidder', type: 'address' },
    ],
    name: 'claimable',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_bidLimit', type: 'uint256' }],
    name: 'closeAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'currentAuctionId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'maxAuctionLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'operatorAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'recoverToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address[]', name: '_bidders', type: 'address[]' }],
    name: 'removeWhitelist',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  { inputs: [], name: 'renounceOwnership', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'uint256', name: '_maxAuctionLength', type: 'uint256' }],
    name: 'setMaxAuctionLength',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '_operatorAddress', type: 'address' }],
    name: 'setOperatorAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '_startBlock', type: 'uint256' },
      { internalType: 'uint256', name: '_endBlock', type: 'uint256' },
      { internalType: 'uint256', name: '_initialBidAmount', type: 'uint256' },
      { internalType: 'uint256', name: '_leaderboard', type: 'uint256' },
    ],
    name: 'startAuction',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalCollected',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewAuctions',
    outputs: [
      {
        components: [
          { internalType: 'enum FarmAuction.Status', name: 'status', type: 'uint8' },
          { internalType: 'uint256', name: 'startBlock', type: 'uint256' },
          { internalType: 'uint256', name: 'endBlock', type: 'uint256' },
          { internalType: 'uint256', name: 'initialBidAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'leaderboard', type: 'uint256' },
          { internalType: 'uint256', name: 'leaderboardThreshold', type: 'uint256' },
        ],
        internalType: 'struct FarmAuction.Auction[]',
        name: '',
        type: 'tuple[]',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'bidder', type: 'address' },
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewBidderAuctions',
    outputs: [
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'uint256[]', name: '', type: 'uint256[]' },
      { internalType: 'bool[]', name: '', type: 'bool[]' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewBidders',
    outputs: [
      { internalType: 'address[]', name: '', type: 'address[]' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'auctionId', type: 'uint256' },
      { internalType: 'uint256', name: 'cursor', type: 'uint256' },
      { internalType: 'uint256', name: 'size', type: 'uint256' },
    ],
    name: 'viewBidsPerAuction',
    outputs: [
      {
        components: [
          { internalType: 'address', name: 'account', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'bool', name: 'hasClaimed', type: 'bool' },
        ],
        internalType: 'struct FarmAuction.Bid[]',
        name: '',
        type: 'tuple[]',
      },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'bidder', type: 'address' }],
    name: 'whitelisted',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const
