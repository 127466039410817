import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const NeoBankIcon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="24" height="20" viewBox="0 0 24 20" fill="none">
      <path
        d="M1.6 7.9995H4V14.3995H2.4C2.18783 14.3995 1.98434 14.4838 1.83431 14.6338C1.68429 14.7838 1.6 14.9873 1.6 15.1995C1.6 15.4117 1.68429 15.6152 1.83431 15.7652C1.98434 15.9152 2.18783 15.9995 2.4 15.9995H21.6C21.8122 15.9995 22.0157 15.9152 22.1657 15.7652C22.3157 15.6152 22.4 15.4117 22.4 15.1995C22.4 14.9873 22.3157 14.7838 22.1657 14.6338C22.0157 14.4838 21.8122 14.3995 21.6 14.3995H20V7.9995H22.4C22.5741 7.99933 22.7433 7.94239 22.8821 7.83731C23.0209 7.73224 23.1216 7.58478 23.169 7.41729C23.2164 7.2498 23.2078 7.07142 23.1447 6.90922C23.0815 6.74702 22.9672 6.60984 22.819 6.5185L12.419 0.118502C12.293 0.0410205 12.1479 0 12 0C11.8521 0 11.707 0.0410205 11.581 0.118502L1.181 6.5185C1.03283 6.60984 0.91849 6.74702 0.85533 6.90922C0.792171 7.07142 0.783636 7.2498 0.831022 7.41729C0.878408 7.58478 0.979128 7.73224 1.1179 7.83731C1.25668 7.94239 1.42594 7.99933 1.6 7.9995ZM5.6 7.9995H8.8V14.3995H5.6V7.9995ZM13.6 7.9995V14.3995H10.4V7.9995H13.6ZM18.4 14.3995H15.2V7.9995H18.4V14.3995ZM12 1.7385L19.574 6.3995H4.426L12 1.7385ZM24 19.2C24 19.4122 23.9157 19.6157 23.7657 19.7657C23.6157 19.9157 23.4122 20 23.2 20H0.8C0.587827 20 0.384344 19.9157 0.234315 19.7657C0.0842854 19.6157 0 19.4122 0 19.2C0 18.9878 0.0842854 18.7843 0.234315 18.6343C0.384344 18.4843 0.587827 18.4 0.8 18.4H23.2C23.4122 18.4 23.6157 18.4843 23.7657 18.6343C23.9157 18.7843 24 18.9878 24 19.2Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default NeoBankIcon;
