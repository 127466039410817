import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ZedtokenIcon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="21" height="15" viewBox="0 0 21 15" fill="none">
      <path
        d="M15.276 4.29734V3.83317C15.276 1.74317 12.1235 0.166504 7.94271 0.166504C3.76188 0.166504 0.609375 1.74317 0.609375 3.83317V7.1665C0.609375 8.90734 2.79688 10.2907 5.94271 10.7048V11.1665C5.94271 13.2565 9.09521 14.8332 13.276 14.8332C17.4569 14.8332 20.6094 13.2565 20.6094 11.1665V7.83317C20.6094 6.10817 18.491 4.72317 15.276 4.29734ZM4.60938 9.07234C2.97688 8.6165 1.94271 7.86567 1.94271 7.1665V5.994C2.62271 6.47567 3.53354 6.864 4.60938 7.12484V9.07234ZM11.276 7.12484C12.3519 6.864 13.2627 6.47567 13.9427 5.994V7.1665C13.9427 7.86567 12.9085 8.6165 11.276 9.07234V7.12484ZM9.94271 13.0723C8.31021 12.6165 7.27604 11.8657 7.27604 11.1665V10.819C7.49521 10.8273 7.71688 10.8332 7.94271 10.8332C8.26604 10.8332 8.58188 10.8223 8.89188 10.804C9.23626 10.9273 9.58705 11.0319 9.94271 11.1173V13.0723ZM9.94271 9.354C9.28055 9.45183 8.61206 9.50057 7.94271 9.49984C7.27336 9.50057 6.60487 9.45183 5.94271 9.354V7.3715C6.60586 7.45791 7.27395 7.50078 7.94271 7.49984C8.61146 7.50078 9.27956 7.45791 9.94271 7.3715V9.354ZM15.276 13.354C13.9498 13.5484 12.6023 13.5484 11.276 13.354V11.3665C11.939 11.4556 12.6071 11.5002 13.276 11.4998C13.9448 11.5008 14.6129 11.4579 15.276 11.3715V13.354ZM19.276 11.1665C19.276 11.8657 18.2419 12.6165 16.6094 13.0723V11.1248C17.6852 10.864 18.596 10.4757 19.276 9.994V11.1665Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default ZedtokenIcon;
