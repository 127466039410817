import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | ZedDex',
  defaultTitle: 'ZedDex',
  description:
    'Cheaper and faster than Uniswap? Discover ZedDex, the leading DEX on ZEDX Smart Chain (ZEDX) with the best farms in DeFi and a lottery for ZED.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@ZedDex',
    site: '@ZedDex',
  },
  openGraph: {
    title: 'ZedDex - A next evolution DeFi exchange on ZEDX Smart Chain (ZEDX)',
    description:
      'ZedDex part of ZEDXION Network Ecosystem, earn ZED through farming, staking or lottery,  swap your tokens with security and confidence.',
    // images: [{ url: process.env.NEXT_PUBLIC_URL + '/images/hero.png?v=1.0' }],
  },
}
