import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Social Media"),
    items: [
      {
        label: t("Gchain Twitter"),
        href: "https://twitter.com/ZEDXIONChain",
      },
      {
        label: t("ZedDex Twitter"),
        href: "https://twitter.com/BlueLotusZEDX",
      },
      {
        label: t("ZEDXION View app Twitter"),
        href: "https://twitter.com/GvaApp",
      },
      {
        label: t("ZEDXION Network Discord"),
        href: "https://discord.gg/XjHDMXQTjT",
      },
      {
        label: t("Blue Hosting Twitter"),
        href: "https://twitter.com/BlueHostingGsys",
      },
      {
        label: t("ZEDXION Network Telegram"),
        href: "https://web.telegram.org/a/#-1619157049",
      },
    ],
  },
  {
    label: t("ZEDXION Network Ecosystem"),
    items: [
      {
        label: t("ZEDXION Faucet"),
        href: "https://faucet.ZEDXION.network/",
      },
      {
        label: t("Gchain Testnet"),
        href: "https://testnet-zedscan.net/",
      },
      {
        label: t("Blue Hosting"),
        href: "https://www.bluehostinzedx.com/en/",
      },
      {
        label: t("ZEDXION View app"),
        href: "https://www.ZEDXIONviewapp.io/",
      },
      {
        label: t("ZEDXION Web Wallet"),
        href: "https://ZEDXIONwallet.io/#/",
      },
      {
        label: t("ZedDex"),
        href: "https://www.zeddex.com/",
      },
      {
        label: t("Genesis of Blue NFT Marketplace"),
        href: "https://www.genesisofblue.io/",
      },
    ],
  },
  {
    label: t("About Gchain"),
    items: [
      {
        label: "Contact Us",
        href: "https://helpdesk.ZEDXION.network/",
      },
      {
        label: "Network Status",
        href: "https://status.ZEDXION.network/",
      },
      {
        label: "API Documentation",
        href: "https://docs.ZEDXION.network/",
      },
      {
        label: "Contact us for banners",
        href: "https://zedscan.net/address/0xBCeE7f35DD80f232a9123F903831019D8bAa9D91#",
      },
      {
        label: "ZEDXION Network Whitepaper",
        href: "https://genesyblockchain.gitbook.io/ZEDXION-blockchain",
      },
      {
        label: "Brand Assets",
        href: "https://genesyblockchain.gitbook.io/ZEDXION-blockchain/blue-lotus-governance/brand-and-logo",
      },
      {
        label: "Blockscan",
        href: "https://zedscan.net/address/0xBCeE7f35DD80f232a9123F903831019D8bAa9D91#",
      },
      {
        label: "Terms of Service",
        href: "https://genesyblockchain.gitbook.io/ZEDXION-blockchain/ZEDXION-blockchain-gchain/gchain-terms-and-conditions",
      },
    ],
  },
];
