import styled from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";
import { Link } from "../Link";

export const StyledFooter = styled(Flex)`
  color: ${({ theme }) => theme.colors.text}
  background: ${({ theme }) => theme.colors.backgroundAlt}
`;

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0px;
  }
`;

export const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &:first-child {
    color: #3a8eff;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const StyledToolsContainer = styled(Flex)`
  border-color: ${darkColors.cardBorder};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`;

export const StyledSocialLinks = styled(SocialLinks)`
  border-bottom: 1px solid #344357;
`;

export const StyledText = styled.span`
  color: #27DBDC;
`;

export const StyledLinkFlex = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #313743;
  flex: 1 1 calc(50% - 10px);
  ${({ theme }) => theme.mediaQueries.sm} {
    flex: 1 1 auto;
    &:nth-child(3) {
      flex: 1 1 calc(100% - 410px);
    }
  }
`;

export const StyledLink = styled(Link)`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text};
  &:hover {
    text-decoration: none;
  }
`;