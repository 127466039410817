import { WalletConfigV2 } from '@pancakeswap/ui-wallets'
import { WalletFilledIcon } from '@pancakeswap/uikit'
import type { ExtendEthereum } from 'global'
import { isFirefox } from 'react-device-detect'
import { getTrustWalletProvider } from '@pancakeswap/wagmi/connectors/trustWallet'
import { walletConnectNoQrCodeConnector } from '../utils/wagmi'
import { ASSET_CDN } from './constants/endpoints'

export enum ConnectorNames {
  MetaMask = 'metaMask',
  Injected = 'injected',
  WalletConnect = 'walletConnect',
  WalletConnectV1 = 'walletConnectLegacy',
  BSC = 'bsc',
  Blocto = 'blocto',
  WalletLink = 'coinbaseWallet',
  Ledger = 'ledger',
  TrustWallet = 'trustWallet',
}

const createQrCode = (chainId: number, connect) => async () => {
  connect({ connector: walletConnectNoQrCodeConnector, chainId })

  const r = await walletConnectNoQrCodeConnector.getProvider()
  return new Promise<string>((resolve) => {
    r.on('display_uri', (uri) => {
      resolve(uri)
    })
  })
}

const isMetamaskInstalled = () => {
  if (typeof window === 'undefined') {
    return false
  }

  if (window.ethereum?.isMetaMask) {
    return true
  }

  if (window.ethereum?.providers?.some((p) => p.isMetaMask)) {
    return true
  }

  return false
}

const walletsConfig = ({
  chainId,
  connect,
}: {
  chainId: number
  connect: (connectorID: ConnectorNames) => void
}): WalletConfigV2<ConnectorNames>[] => {
  const qrCode = createQrCode(chainId, connect)
  return [
    {
      id: 'metamask',
      title: 'Metamask',
      icon: `/images/wallets/metamask.png`,
      get installed() {
        return isMetamaskInstalled()
        // && metaMaskConnector.ready
      },
      connectorId: ConnectorNames.MetaMask,
      deepLink: 'https://metamask.app.link/dapp/zeddex.com//',
      qrCode,
      downloadLink: 'https://metamask.app.link/dapp/zeddex.com//',
    },
    // {
    //   id: 'binance',
    //   title: 'Binance Wallet',
    //   icon: `/images/wallets/binance.png`,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean(window.BinanceChain)
    //   },
    //   connectorId: ConnectorNames.BSC,
    //   guide: {
    //     desktop: 'https://www.bnbchain.org/en/binance-wallet',
    //   },
    //   downloadLink: {
    //     desktop: isFirefox
    //       ? 'https://addons.mozilla.org/en-US/firefox/addon/binance-chain/?src=search'
    //       : 'https://chrome.google.com/webstore/detail/binance-wallet/fhbohimaelbohpjbbldcngcnapndodjp',
    //   },
    // },
    // {
    //   id: 'coinbase',
    //   title: 'Coinbase Wallet',
    //   icon: `/images/wallets/coinbase.png`,
    //   connectorId: ConnectorNames.WalletLink,
    // },
    // {
    //   id: 'trust',
    //   title: 'Trust Wallet',
    //   icon: `/images/wallets/trust.png`,
    //   connectorId: ConnectorNames.TrustWallet,
    //   get installed() {
    //     return !!getTrustWalletProvider()
    //   },
    //   deepLink: 'https://link.trustwallet.com/open_url?coin_id=20000714&url=https://zeddex.com/',
    //   downloadLink: 'https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph',
    //   guide: {
    //     desktop: 'https://trustwallet.com/browser-extension',
    //     mobile: 'https://trustwallet.com/',
    //   },
    //   qrCode,
    // },
    // {
    //   id: 'walletconnect',
    //   title: 'WalletConnect',
    //   icon: `/images/wallets/walletconnect.png`,
    //   connectorId: ConnectorNames.WalletConnect,
    // },
    // {
    //   id: 'opera',
    //   title: 'Opera Wallet',
    //   icon: `/images/wallets/opera.png`,
    //   connectorId: ConnectorNames.Injected,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean(window.ethereum?.isOpera)
    //   },
    //   downloadLink: 'https://www.opera.com/crypto/next',
    // },
    // {
    //   id: 'brave',
    //   title: 'Brave Wallet',
    //   icon: `/images/wallets/brave.png`,
    //   connectorId: ConnectorNames.Injected,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean(window.ethereum?.isBraveWallet)
    //   },
    //   downloadLink: 'https://brave.com/wallet/',
    // },
    // {
    //   id: 'math',
    //   title: 'MathWallet',
    //   icon: `/images/wallets/mathwallet.png`,
    //   connectorId: ConnectorNames.Injected,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean(window.ethereum?.isMathWallet)
    //   },
    //   qrCode,
    // },
    // {
    //   id: 'tokenpocket',
    //   title: 'TokenPocket',
    //   icon: `/images/wallets/tokenpocket.png`,
    //   connectorId: ConnectorNames.Injected,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean(window.ethereum?.isTokenPocket)
    //   },
    //   qrCode,
    // },
    // {
    //   id: 'safepal',
    //   title: 'SafePal',
    //   icon: `/images/wallets/safepal.png`,
    //   connectorId: ConnectorNames.Injected,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean((window.ethereum as ExtendEthereum)?.isSafePal)
    //   },
    //   downloadLink:
    //     'https://chrome.google.com/webstore/detail/safepal-extension-wallet/lgmpcpglpngdoalbgeoldeajfclnhafa',
    //   qrCode,
    // },
    // {
    //   id: 'coin98',
    //   title: 'Coin98',
    //   icon: `/images/wallets/coin98.png`,
    //   connectorId: ConnectorNames.Injected,
    //   get installed() {
    //     return (
    //       typeof window !== 'undefined' &&
    //       (Boolean((window.ethereum as ExtendEthereum)?.isCoin98) || Boolean(window.coin98))
    //     )
    //   },
    //   qrCode,
    // },
    // {
    //   id: 'blocto',
    //   title: 'Blocto',
    //   icon: `/images/wallets/blocto.png`,
    //   connectorId: ConnectorNames.Blocto,
    //   get installed() {
    //     return typeof window !== 'undefined' && Boolean((window.ethereum as ExtendEthereum)?.isBlocto)
    //       ? true
    //       : undefined // undefined to show SDK
    //   },
    // },
    // {
    //   id: 'ledger',
    //   title: 'Ledger',
    //   icon: `/images/wallets/ledger.png`,
    //   connectorId: ConnectorNames.Ledger,
    // },
  ]
}

export const createWallets = (chainId: number, connect: any) => {
  const hasInjected = typeof window !== 'undefined' && !window.ethereum
  const config = walletsConfig({ chainId, connect })
  return hasInjected && config.some((c) => c.installed && c.connectorId === ConnectorNames.Injected)
    ? config // add injected icon if none of injected type wallets installed
    : [
        ...config,
        // {
        //   id: 'injected',
        //   title: 'Injected',
        //   icon: WalletFilledIcon,
        //   connectorId: ConnectorNames.Injected,
        //   installed: typeof window !== 'undefined' && Boolean(window.ethereum),
        // },
      ]
}

const docLangCodeMapping: Record<string, string> = {
  it: 'italian',
  ja: 'japanese',
  fr: 'french',
  tr: 'turkish',
  vi: 'vietnamese',
  id: 'indonesian',
  'zh-cn': 'chinese',
  'pt-br': 'portuguese-brazilian',
}

export const getDocLink = (code: string) =>
  docLangCodeMapping[code]
    ? `https://docs.pancakeswap.finance/v/${docLangCodeMapping[code]}/get-started/wallet-guide`
    : `https://docs.pancakeswap.finance/get-started/wallet-guide`
