import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ZeddropIcon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="19" height="15" viewBox="0 0 19 15" fill="none">
      <path
        d="M18.6761 7.63442L12.1555 14.1549C12.0643 14.2462 11.9481 14.3084 11.8216 14.3336C11.695 14.3588 11.5638 14.3459 11.4446 14.2965C11.3254 14.2471 11.2235 14.1635 11.1519 14.0561C11.0802 13.9488 11.0421 13.8227 11.0422 13.6936V10.4521C6.38814 10.7162 3.19716 13.7344 2.3014 14.6904C2.16075 14.8407 1.9763 14.9427 1.7743 14.982C1.5723 15.0213 1.36305 14.9959 1.17633 14.9094C0.989608 14.8229 0.834924 14.6797 0.734294 14.5002C0.633665 14.3207 0.592215 14.114 0.615846 13.9096C0.918235 11.2802 2.35846 8.75106 4.67161 6.78838C6.59272 5.15825 8.92788 4.12393 11.0422 3.94299V0.652566C11.0421 0.523528 11.0802 0.39736 11.1519 0.290036C11.2235 0.182713 11.3254 0.0990581 11.4446 0.0496636C11.5638 0.000268968 11.695 -0.0126447 11.8216 0.0125574C11.9481 0.0377595 12.0643 0.0999441 12.1555 0.191239L18.6761 6.71176C18.7367 6.77232 18.7848 6.84423 18.8176 6.92339C18.8504 7.00255 18.8673 7.0874 18.8673 7.17309C18.8673 7.25878 18.8504 7.34363 18.8176 7.42279C18.7848 7.50195 18.7367 7.57386 18.6761 7.63442Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default ZeddropIcon;
