import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const ICAKE = {
  [ChainId.BSC]: '0xc6D8772E332C34ae7671317c6BDFF2B5B161d19e',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.ZEDX]: '0x58520a4B90c12BD91b4b3613D82cf712319a4A27',
  [ChainId.ERAM]: '0xB98C6b1D7eF0413409f5b129e26545C20A4292f7',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_VAULT = {
  [ChainId.BSC]: '0x40ad72a13Ac028bc39721a569490eDb8401CB0fc',
  [ChainId.BSC_TESTNET]: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.ZEDX]: '0x9AA96373C959E39BcFf6d3F127438Ac4CE7F0652',
  [ChainId.ERAM]: '0x7b5CbB38cb84d3d1580EBa93EbED551d0B7d7412',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_FLEXIBLE_SIDE_VAULT = {
  [ChainId.BSC]: '0x18D7d102e1098C8e29DEE57524c3a37b71e81f90',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.ZEDX]: '0xBbF9028D4803FFac427c6c40c7cC2844cC04457E',
  [ChainId.ERAM]: '0x5a2147f1eEf3EE9BE0b08f8DDEE5A51119AA6baE',
} as const satisfies ContractAddresses<SupportedChainId>
