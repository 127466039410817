import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const ZedCexIcon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M19.6241 5.08524L10.3384 0.0852432C10.2344 0.0292883 10.1181 0 10 0C9.88189 0 9.76562 0.0292883 9.66161 0.0852432L0.375893 5.08524C0.262868 5.14604 0.168312 5.23617 0.102166 5.34615C0.0360196 5.45613 0.000727559 5.58191 1.24585e-07 5.71024V11.4245C-7.61109e-05 11.5536 0.0348362 11.6803 0.101025 11.7912C0.167213 11.902 0.262203 11.9928 0.375893 12.054L9.66161 17.054C9.76562 17.1099 9.88189 17.1392 10 17.1392C10.1181 17.1392 10.2344 17.1099 10.3384 17.054L19.6241 12.054C19.7378 11.9928 19.8328 11.902 19.899 11.7912C19.9652 11.6803 20.0001 11.5536 20 11.4245V5.71024C19.9993 5.58191 19.964 5.45613 19.8978 5.34615C19.8317 5.23617 19.7371 5.14604 19.6241 5.08524ZM18.5714 10.229L15.4866 8.56739L18.5714 6.90578V10.229ZM13.9795 7.75578L10.7143 5.99774V1.90578L17.7795 5.71024L13.9795 7.75578ZM10 9.89864L7.52679 8.56739L10 7.23614L12.4732 8.56739L10 9.89864ZM9.28571 1.90578V5.99774L6.02054 7.75578L2.22054 5.71024L9.28571 1.90578ZM1.42857 6.90578L4.51339 8.56739L1.42857 10.229V6.90578ZM6.02054 9.37899L9.28571 11.137V15.229L2.22054 11.4245L6.02054 9.37899ZM10.7143 15.229V11.137L13.9795 9.37899L17.7795 11.4245L10.7143 15.229Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default ZedCexIcon;
