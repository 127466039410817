import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <path
        d="M8.37265 6.85003C8.48062 6.68915 8.56752 6.53355 8.68602 6.3964C9.3075 5.66849 10.1133 5.30453 11.0534 5.23596C11.6433 5.19376 12.2279 5.24651 12.7967 5.42057C13.8369 5.73706 14.4558 6.45706 14.7639 7.47772C14.993 8.23465 15.0509 9.01268 15.0535 9.79598C15.0588 11.6448 15.0535 13.4962 15.0588 15.345C15.0588 15.4637 15.0298 15.498 14.9087 15.4953C13.966 15.4901 13.0232 15.4901 12.0778 15.4953C11.9646 15.4953 11.9409 15.4611 11.9409 15.3556C11.9435 13.665 11.9435 11.9718 11.9435 10.2813C11.9435 9.854 11.9145 9.42938 11.7908 9.01795C11.5959 8.36388 11.1456 8.01047 10.4636 7.97619C10.2608 7.96564 10.0606 7.97619 9.86051 8.01575C9.15476 8.15025 8.77555 8.6118 8.60175 9.27905C8.48062 9.74323 8.47272 10.218 8.47008 10.6927C8.46745 12.2435 8.46745 13.7942 8.47272 15.3476C8.47272 15.4637 8.44638 15.5006 8.32261 15.5006C7.38249 15.4953 6.44501 15.4953 5.50489 15.5006C5.39166 15.5006 5.35742 15.4769 5.35742 15.3582C5.36006 12.1142 5.36006 8.8729 5.35742 5.62893C5.35742 5.51552 5.37849 5.47332 5.50489 5.47332C6.40024 5.4786 7.29559 5.4786 8.19094 5.47332C8.30681 5.47332 8.34895 5.49706 8.34631 5.62365C8.33841 5.98497 8.34368 6.34365 8.34368 6.70497C8.34631 6.74453 8.33315 6.78937 8.37265 6.85003Z"
      />
      <path
        d="M0.301461 10.4869C0.301461 8.86496 0.304095 7.24561 0.298828 5.62362C0.298828 5.5023 0.333062 5.47066 0.451564 5.47329C1.39432 5.47857 2.33707 5.47857 3.28245 5.47329C3.39042 5.47329 3.42202 5.49967 3.42202 5.61044C3.41939 8.86232 3.41939 12.1142 3.42202 15.3634C3.42202 15.4769 3.38779 15.5006 3.28245 15.498C2.33443 15.4927 1.38642 15.4927 0.438397 15.498C0.314628 15.498 0.298828 15.4584 0.298828 15.3476C0.304095 13.7283 0.301461 12.1089 0.301461 10.4869Z"
      />
      <path
        d="M0.0586002 2.30036C0.0612336 1.30079 0.861782 0.496393 1.85457 0.49903C2.85789 0.501667 3.66634 1.30607 3.66634 2.30563C3.66634 3.31311 2.84999 4.12014 1.83087 4.11751C0.853882 4.11223 0.0559669 3.29728 0.0586002 2.30036Z"
      />
    </Svg>
  );
};

export default Icon;
