import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_ONLY_ZEDX = [ChainId.ZEDX]
export const SUPPORT_ONLY_ERAM = [ChainId.ERAM]
export const SUPPORT_FARMS = [
  ChainId.BSC,
  // ChainId.BSC_TESTNET,
  // ChainId.ETHEREUM,
  // ChainId.GOERLI,
  // ChainId.ZKSYNC_TESTNET,
  // ChainId.POLYGON_ZKEVM_TESTNET,
  // ChainId.POLYGON_ZKEVM,
  // ChainId.ZKSYNC,
  ChainId.ZEDX,
  ChainId.ERAM,
]
