import useSWRImmutable from 'swr/immutable'

export const useCakePrice = () => {
  return useSWRImmutable(
    ['cake-usd-price'],
    async () => {
      // const zed = await (await fetch('https://zeddex.com/api/farms/price/cake')).json()
      // return zed.price as string
      const zed = await(await fetch('https://openapi.zedcex.com/open/api/get_ticker?symbol=zedxusdt')).json()
      return zed.data.last as string
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}
